.mainContainerDesk{
  margin: auto;
  width: 50%;
  height: auto;
  padding: 10px;
  margin-top: 2rem;
  border: 3px solid;
  border-color: #0074bc;
}
.mainContainerMob{
  margin: auto;
  width: 100%;
  height: auto;
  padding: 10px;
}
.app {
}
.app1{
  border: 3px solid;
  padding: 10px;
}