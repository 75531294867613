.mainCaraousel {
  max-width: 1250px;
  margin: 0.5px auto;
}
.carouselItem {
  border-radius: 20px;
  width: 1250px;
  height: 480px;
}


@media (max-width: 480px) {
  .carouselImgContainer {
    margin: 0.5px auto;
    width: 92%;
    height: 180px;
  }
  .carouselItem {
    width: 1250px;
    height: 180px;
  }

  .mainCaraousel {
    margin-bottom: 0rem;
  }

   .anchorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .carouselImgContainer {
    margin: 0.5px auto;
    width: 92%;
    height: 180px;
  }
  .carouselItem {
    width: 1250px;
    height: 180px;
  }

  .mainCaraousel {
    margin-bottom: 0rem;
  }

   .anchorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .carouselImgContainer {
    margin: 0.5px auto;
    width: 92%;
    height: 180px;
  }
  .carouselItem {
    width: 1250px;
    height: 180px;
  }

  .mainCaraousel {
    margin-bottom: 0rem;
  }

   .anchorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}