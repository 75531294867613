#block_container {
    display: flex;
    justify-content: left;
}

.typo{
    font-weight:bold;
    text-align: center;
}


.list_container {
    display: flex;
    justify-content: left;
}

.button_container {
    display: flex;
    justify-content: left;
}
.button_container1 {
    display: flex;
    justify-content: center;
}
.button_container {
    display: flex;
    justify-content: center;
}

#block2 {
    margin: 24px;
}

.list_block1 {
    background-color: #0074bc;
    border-radius: 12px;
    margin: 6px;
    width: 50%;
    border: 2px solid #121110;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.list_block1:hover{
    background-color: #70cc8f;
}

.list_block1 p {
    font-weight: bold;
    color: #ffffff;
}

.list_block2 {
    background-color: #0074bc;
    border-radius: 12px;
    margin: 6px;
    width: 50%;
    border: 2px solid #121110;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.list_block2:hover{
    background-color: #70cc8f;
}
.list_block2 p {
    font-weight: bold;
    color: #ffffff;
}
.list_block3 {
    background-color: #0074bc;
    border-radius: 12px;
    margin: 6px;
    width: 50%;
    border: 2px solid #121110;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.list_block3:hover{
    background-color:rgb(240, 46, 46);
}

.list_block3 p {
    font-weight: bold;
    color: #ffffff;
}
.btn1 {
    background-color: red;
    margin: 6px;
    width: 50%;
    border: 2px solid #121110;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.btn1 p {
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
    margin: 0px;
}

.btn2 {
    background-color: red;
    margin: 6px;
    width: 50%;
    border: 2px solid #121110;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.btn2 p {
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
    margin: 0px;
}
.qrcode{
    display: flex;
    justify-content: center;
}
.qrcode_container{
    width: 1220px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.qrcode_container p{
    display: flex;
    justify-content: center;
    margin: 0px;
    text-align: center;
}
@media (max-width: 480px) {
    .typo{
        font-weight:bold;
        text-align: center;
        margin-left: 20px;
    }
}
@media screen and (max-width: 500px) {
    .typo{
        font-weight:bold;
        text-align: center;
        margin-left: 20px;
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .typo{
        font-weight:bold;
        text-align: center;
        margin-left: 20px;
    }
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .typo{
        font-weight:bold;
        text-align: center;
        margin-left: 20px;
    }
  }